.CardSwiper {
	/* background: lightblue; */
	user-select: none;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100%;
	/* cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto; */
  }
  
  .CardSwiper > div {
	position: absolute;
	width: 100vw;
	/* height: 100vh; */
	will-change: transform;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .CardSwiper > div > div {
	/* background-color: white;
	background-size: auto 85%;
	background-repeat: no-repeat;
	background-position: center center; */
	/* width: 45vh; */
	/* max-width: 300px; */
	/* height: 85vh; */
	/* max-height: 570px; */
	will-change: transform;
	/* border-radius: 10px; */
	/* box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3); */
  }